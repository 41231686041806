<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-7 pl-7' : 'px-5'">
    <Snackbar ref="snackbar" />
    <div class="mt-5">
      <template v-if="process.run">
        <v-card loading outlined class="mt-3 elevation-0 rounded-lg">
          <div class="pa-4 text-center">
            <v-icon size="30">mdi-timer-sand</v-icon>
            <div class="pt-2">Content Loading . . .</div>
          </div>
        </v-card>
      </template>
      <template v-else>
        <div class="title black--text mb-2">
          Detail Trainer ({{ trainer_detail.fullname }})
        </div>
        <Breadcrumbs :items="breadcrumbs" />
        <v-card outlined class="pa-4 mt-3 mb-5 rounded-lg" v-if="Object.keys(trainer_detail).length > 0">
          <v-img cover width="250" height="250" class="rounded-xl" :src="require('@/assets/img/profile_picture.png')"></v-img>
          <div class="pt-3 text-body-1 font-weight-medium">
            Detail Trainer
          </div>
          <div class="pt-3 body-2">
            <div class="d-flex flex-wrap">
              <v-col cols="12" md="2" class="pt-2 px-0">
                <div class="grey--text text--darken-1">Trainer Name</div>
              </v-col>
              <v-col cols="12" md="10" class="pt-0 pt-md-2 px-0">
                <div>{{ trainer_detail.fullname }}</div>
              </v-col>
            </div>
            <div class="d-flex flex-wrap">
              <v-col cols="12" md="2" class="pt-2 px-0">
                <div class="grey--text text--darken-1">Email Address</div>
              </v-col>
              <v-col cols="12" md="10" class="pt-0 pt-md-2 px-0">
                <div>{{ trainer_detail.email }}</div>
              </v-col>
            </div>
            <div class="d-flex flex-wrap">
              <v-col cols="12" md="2" class="pt-2 px-0">
                <div class="grey--text text--darken-1">Phone Number</div>
              </v-col>
              <v-col cols="12" md="10" class="pt-0 pt-md-2 px-0">
                <div>{{ trainer_detail.phone_number }}</div>
              </v-col>
            </div>
            <!-- <div class="d-flex flex-wrap">
              <v-col cols="12" md="2" class="pt-2 px-0">
                <div class="grey--text text--darken-1">Description</div>
              </v-col>
              <v-col cols="12" md="10" class="pt-0 pt-md-2 px-0">
                <div>{{ trainer_detail.description }}</div>
              </v-col>
            </div> -->
            <div class="d-flex flex-wrap">
              <v-col cols="12" md="2" class="pt-2 px-0">
                <div class="grey--text text--darken-1">Created at</div>
              </v-col>
              <v-col cols="12" md="10" class="pt-0 pt-md-2 px-0">
                <div>{{ trainer_detail.created_at | dateFull }}</div>
              </v-col>
            </div>
            <!-- <v-col cols="12" class="pb-1 px-0">
              <div class="text-body-1 font-weight-medium">
                Settings
              </div>
            </v-col>
            <v-col cols="12" class="pt-2 px-0 d-flex">
              <div class="grey--text text--darken-1">Enable Notification</div>
              <div class="pl-3">{{ trainer_detail.setting.enable_notifiicatiion ? "Yes" : "No" }}</div>
            </v-col>
            <v-col cols="12" class="pt-2 px-0 d-flex">
              <div class="grey--text text--darken-1">One Time Update Profile</div>
              <div class="pl-3">{{ trainer_detail.setting.one_time_update_profile ? "Yes" : "No" }}</div>
            </v-col> -->
          </div>
        </v-card>
      </template>
    </div>
  </div>
</template>

<script>
import { get, post, put, destroy } from "@/service/Axios";
import Pagination from "@/components/Pagination.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Snackbar from "@/components/Snackbar.vue";

export default {
  components: {
    Pagination,
    Breadcrumbs,
    Snackbar,
  },
  data() {
    return {
      title: "List Users",
      breadcrumbs: [
        {
          text: "List Trainer",
          disabled: false,
          href: `/company/trainer-list/${this.$route.params.company_id}`,
        },
        {
          text: "Detail Trainer",
          disabled: true,
          href: "#",
        },
      ],
      trainer_detail: {},
      process: {
        run: false,
      },
    };
  },
  watch: {
  },
  created() {
    this.getDetailTrainer();
  },
  mounted() {},
  methods: {
    async getDetailTrainer() {
      this.process.run = true;

      await get(`trainer/v1/detail/${this.$route.params.id}`).then(
        (response) => {
          let res = response.data;
          this.trainer_detail = res.data;
          this.process.run = false;
          this.$store.state.process.run = false;
        }
      );
    },
  },
};
</script>

<style></style>